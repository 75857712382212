<template>
  <div class="promise-fill">
    <van-nav-bar left-arrow :title="title" @click-left="$router.back()" />
    <van-form
      :show-error-message="false"
      label-width="50vw"
      class="wrapper-height__feature-btn van-form__text-color"
      @submit="onSubmit"
    >
      <van-field
        v-if="isReplenish"
        v-model="info.remark"
        :autosize="true"
        label="补录原因"
        maxlength="200"
        name="content"
        label-width="20vw"
        placeholder="请输入补录原因"
        rows="2"
        show-word-limit
        type="textarea"
        :rules="[{ required: true, message: '部门名称' }]"
        required
        @input="handleInput('remark')"
      />
      <div v-if="isReplenish" class="line-block" />
      <!--   部门   -->
      <van-field
        v-model="info.departmentName"
        name="departmentName"
        label="部门名称"
        input-align="right"
        readonly
        :rules="[{ required: true, message: '部门名称' }]"
        required
      />
      <!-- @click="departmentVisible = true" -->
      <div class="line-block"></div>
      <!--   核心   -->
      <van-field
        v-for="(item, idx) in dynamicFormConf"
        :key="idx"
        :label="item.label"
        input-align="right"
        disabled
        :rules="[{ required: true, pattern: /[0-9]+/, message: item.label }]"
        required
      >
        <template #input>
          <van-stepper
            v-if="!item.type"
            v-model="info[item.key]"
            min="0"
            max="9999"
            integer
            default-value="0"
          />
          <van-radio-group
            v-else-if="item.type === 'dic'"
            v-model="info[item.key]"
            direction="horizontal"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
          <van-radio-group
            v-else
            v-model="info[item.key]"
            direction="horizontal"
          >
            <van-radio name="1">受控</van-radio>
            <van-radio name="0">不受控</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="line-block"></div>
      <van-field
        v-if="+info.riskTroubleDescFlag"
        v-model="info.riskTroubleDesc"
        :autosize="true"
        label="安全风险及隐患描述"
        maxlength="1000"
        name="riskTroubleDesc"
        label-width="20vw"
        placeholder="请输入安全风险及隐患描述"
        rows="2"
        show-word-limit
        type="textarea"
        label-align="top"
        required
        :rules="[{ required: true, message: '安全风险及隐患描述' }]"
        @input="handleInput('riskTroubleDesc')"
      />
      <div v-if="+info.riskTroubleDescFlag" class="line-block" />
      <van-field
        v-if="+info.controlMeasuresFlag"
        v-model="info.controlMeasures"
        label-align="top"
        :autosize="true"
        label="管控措施"
        maxlength="1000"
        name="controlMeasures"
        label-width="20vw"
        placeholder="请输入管控措施"
        rows="2"
        show-word-limit
        type="textarea"
        :rules="[{ required: true, message: '管控措施' }]"
        required
        @input="handleInput('controlMeasures')"
      />
      <div v-if="+info.controlMeasuresFlag" class="line-block" />
      <van-field
        v-if="+info.signatureFlag"
        label="承诺人签名"
        name="signature"
        readonly
        class="sign-field"
        required
      >
        <template #button>
          <van-button
            v-if="!info.signature"
            round
            class="sign-btn"
            size="mini"
            native-type="button"
            @click="signToggle"
            >电子签名</van-button
          >
          <span v-else class="sign-style" @click="signToggle"
            >电子签名<i class="iconfont iconfont-icoqianming qianming"
          /></span>
          <p v-if="signFlag" class="sign-prom">电子签名是必须的</p>
        </template>
        <i class="iconfont iconfont-icoqianming qianming"></i>
      </van-field>
      <div v-if="+info.signatureFlag" class="line-block" />
      <!--   级别等级   -->
      <van-field
        v-model="info.riskGradeName"
        name="riskGrade"
        label="风险等级"
        input-align="right"
        placeholder="请选择"
        readonly
        @click="popupVisible = true"
      />
      <van-field
        v-model="info.content"
        :autosize="true"
        label="承诺内容"
        maxlength="500"
        name="content"
        label-width="20vw"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('content')"
      />
      <div class="details-page__footer-button-wrapper--fixed">
        <van-button
          :disabled="loading.status"
          :loading="loading.status"
          :loading-text="loading.text"
          block
          native-type="submit"
          round
          type="info"
          >提交</van-button
        >
      </div>
    </van-form>
    <select-popup
      :choose-value="info.riskGrade"
      :data="riskLevelTypes"
      title="风险等级"
      :visible="popupVisible"
      :popup-style="{
        height: '42vh'
      }"
      @close="popupVisible = false"
      @confirm="onPopupConfirm"
    />
    <cascade-department
      v-model="info.department"
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="onDepartmentChange"
    />
  </div>
</template>

<script>
import { loading, whetherDic } from "@/utils/constant";
import { controlDic, promiseFillConf } from "@/views/promise/utils/constant";
import {
  getPromiseInfoById,
  getPromiseRiskGradeTypes,
  getRelatePromiseDeptList,
  updatePromiseInfoById
} from "@/api/psm";
import { filterEmoji, operateMessage } from "@/utils";
import SelectPopup from "@/components/SelectPopup";
import CascadeDepartment from "@/components/CascadeDepartment";
import { mapState } from "vuex";
import esign from "./components/esign";
import { fileUrl } from "@/utils/constant";

export default {
  name: "PromiseFill",
  components: { CascadeDepartment, SelectPopup },
  props: ["id"],
  data() {
    return {
      title: "研判与承诺信息填报",
      signFlag: false,
      loading: { ...loading },
      promiseFillConf,
      whetherDic,
      controlDic,
      dynamicFormConf: [],
      riskLevelTypes: [],
      popupVisible: false,
      signVisible: false,
      departmentVisible: false,
      info: {
        orgCode: "",
        equipmentNum: 0,
        runNum: 0,
        suspendNum: 0,
        unitsNum: 0,
        parameterStatus: 0,
        runStatus: 0,
        useStatus: 0,
        nhStock: 0,
        leakStatus: 0,
        dieselStock: 0,
        methanolStock: 0,
        poiLeakStatus: 0,
        poiLimitStatus: 0,
        poiFallStatus: 0,
        poiJobStatus: 0,
        poiRunStatus: 0,
        poiStorageStatus: 0,
        poiVehicleStatus: 0,
        firesWorkNum: 0,
        fire1WorkNum: 0,
        fire2WorkNum: 0,
        spaceWorkNum: 0,
        blindplateWorkNum: 0,
        temporaryElectricityNum: 0,
        highWorkNum: 0,
        hoistingNum: 0,
        soilWorkNum: 0,
        roadWorkNum: 0,
        inspectionWorkNum: 0,
        workOtherNum: 0,
        pipelineWorkNum: 0,
        implement: 0,
        trialProduction: 0,
        openParking: 0,
        safe: 0,
        contractor: 0,
        control: 0,
        liveSafe: 0,
        changeStatus: 0,
        approve: 0,
        risk: 0,
        trouble: 0,
        content: ""
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    isReplenish() {
      return +this.$route.query?.replenish === 1;
    }
  },
  created() {
    this.getInfo();
    this.getSelectTypes();
  },
  methods: {
    signToggle() {
      if (this.info.signature) {
        //已上传过，直接预览
        esign.showImage(fileUrl + this.info.signature, imageRes => {
          this.info.signature = imageRes.id;
          this.signFlag = false;
        });
        return;
      }
      esign.show(imageRes => {
        this.info.signature = imageRes.id;
        this.signFlag = false;
      });
    },
    onSignConfirm() {},
    handleInput(key) {
      this.info[key] = filterEmoji(this.info[key]);
    },
    onPopupConfirm(row) {
      console.log(row, "row");
      this.info.riskGrade = row.value;
      this.info.riskGradeName = row.label;
    },
    onDepartmentChange(o) {
      console.log(o);
      this.info.departmentName = o.label;
    },
    async getSelectTypes() {
      try {
        this.riskLevelTypes = await getPromiseRiskGradeTypes();
      } catch (e) {
        console.log(e);
      }
    },
    trueValue(value) {
      return value !== undefined && +value !== -1;
    },
    dynamicFormItem() {
      return this.promiseFillConf.filter(item => {
        return !!this.trueValue(this.info[item.key]);
      });
    },
    async getInfo() {
      try {
        const { own } = await getRelatePromiseDeptList(this.id);
        this.info = own;
        this.dynamicFormConf = this.dynamicFormItem();
      } catch (e) {
        console.log(e);
      }
    },
    async onSubmit() {
      console.log(this.info.signatureFlag, this.info.signature);
      if (+this.info.signatureFlag && !this.info.signature) {
        this.signFlag = true;
        return;
      }
      this.info.orgCode = this.userInfo.orgCode;
      try {
        const r = await updatePromiseInfoById(this.id, this.info);
        operateMessage(r, "提交");
        if (r) this.$router.back();
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.promise-fill {
  .van-form {
    overflow: auto;
  }
  .van-cell {
    padding: 12px 16px;
  }
  .van-cell__title,
  .van-cell__value .van-field__body > *:not(textarea) {
    // height: 30px;
    line-height: 30px;
  }
  .line-block {
    height: 8px;
    background-color: #eceef2;
  }
}
.sign-style {
  color: #2e2e4d;
  .qianming {
    position: relative;
    top: 2px;
    left: 3px;
    color: #2eb752;
  }
}
.sign-btn {
  height: 32px;
  padding: 0 16px;
  color: #5d5f63;
}
::v-deep .sign-field .van-field__body {
  .van-field__control {
    display: none !important;
  }
}
::v-deep .van-field--label-top {
  display: block;
  .van-field__label--top {
    width: 100% !important;
  }
}
.sign-prom {
  position: absolute;
  bottom: -6px;
  left: calc(-100% - 60px);
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
</style>
