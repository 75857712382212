<template>
  <div>
    <transition name="van-slide-up">
      <div v-if="visiable" class="EsignView">
        <div class="title">电子签名</div>
        <van-icon class="close" name="cross" @click="close" />
        <template>
          <div ref="esignBox" class="esign-box">
            <van-image v-if="imgUrl" class="image" :src="imgUrl" />
            <div v-else>
              <vue-esign
                v-if="canvasWidth != 0 && canvasHeight != 0"
                ref="esign"
                class="esign"
                :width="canvasWidth"
                :height="canvasHeight"
                :line-width="4"
                bg-color="#fff"
              />
            </div>
          </div>

          <bottom-fixed-box>
            <van-button class="btn-default" @click="reset">清除</van-button>
            <van-button class="btn-primary" @click="handlerConfirm"
              >确定</van-button
            >
          </bottom-fixed-box>
        </template>
      </div>
    </transition>
    <van-dialog
      v-if="visible"
      v-model="visible"
      class="esign-view-dialog"
      overlay-class="esign-view-overlay"
      message="确认提交？"
      show-cancel-button
      :before-close="beforeClose"
    />
  </div>
</template>

<script>
import BottomFixedBox from "@/components/general/BottomFixedBox";
import VueEsign from "./VueEsign";
import { uploadBase64Image } from "@/api/base";

export default {
  name: "EsignView",
  components: { VueEsign, BottomFixedBox },
  data() {
    return {
      visiable: false,
      resultImg: "",
      canvasWidth: 0,
      canvasHeight: 0,
      callback: null,
      showImg: false,
      imgUrl: "",
      visible: false
    };
  },
  created: function() {},
  mounted: function() {},
  methods: {
    show(callback) {
      this.visiable = true;
      this.imgUrl = "";

      this.$nextTick(() => {
        setTimeout(() => {
          //画布宽高=组件宽高
          const esignBox = this.$refs.esignBox;
          this.canvasWidth = esignBox.offsetWidth;
          this.canvasHeight = esignBox.offsetHeight;
        }, 300);
      });
      this.callback = callback;
    },

    /**
     * 展示已有的签名图片
     * @param imgUrl
     */
    showImage(imgUrl, callback) {
      this.visiable = true;
      this.imgUrl = imgUrl;
      this.callback = callback;
    },

    close() {
      this.visiable = false;
      this.callback = null;
    },
    reset() {
      if (this.imgUrl) {
        this.imgUrl = "";
        this.show(this.callback);
      }
      this.$refs.esign && this.$refs.esign.reset();
    },
    handlerConfirm() {
      if (this.imgUrl) {
        this.close();
        return;
      }
      //先判断是否有签名
      if (!this.$refs.esign.hasSign()) {
        this.$toast.fail("请签名");
        return;
      }
      this.visible = true;
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.generate();
      }
      done();
    },
    generate() {
      this.$refs.esign.generate().then(resultImg => {
        uploadBase64Image(resultImg).then(res => {
          //图片上传成功
          this.callback && this.callback(res);
          this.close();
        });
      });
    }
  }
};
</script>

<style lang="scss">
.esign-view-dialog {
  z-index: 99999 !important;
}
.esign-view-overlay {
  z-index: 99999 !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.EsignView {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 99998;
  background: #fff;

  .title {
    width: 100%;
    height: 44px;
    line-height: 44px;
    font-size: 18px;
    text-align: center;
    color: $--color-black-title;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 18px;
    font-size: 20px;
    color: $--color-gray-item-text;
  }

  .image {
    flex-grow: 1;
  }

  .esign-box {
    flex-grow: 1;
  }
}
</style>
